@import "bootstrap/bootstrap";
@import "fontawesome/font-awesome";
@import "sbadmin/sb-admin-2";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, .checkbox label {
	font-weight: 300;
}
label{
  font-weight: 700;
}

.panel{
  margin-top: 1%;
}
#wrapper{overflow:hidden;}
.detallesmodal tr:nth-last-child(-n+5),.detallesmodal tr td:last-child{display: none;}
.detallesmodal #details{overflow:auto}
#details{overflow-y: scroll; height:300px;}
.navbar{position:fixed; width:100%;}
#page-wrapper{padding-top:50px;}
#page-wrapper{padding-bottom:110px;}
#detallesmodal, #detallesmodal .modal-backdrop, #detallesmodal .modal-dialog, #detallesmodal .modal-content, #detallesmodal .modal-body, .detallesmodal, .detallesmodal #details {height: 98% !important; width: 100%; margin: 0;}
div.navbar-default {position:fixed;}

/*# sourceMappingURL=app.css.map */

.spinner {
  margin: 100px auto;
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}